define("bottomline-execution/serializers/bx/material", ["exports", "active-model-adapter"], function (_exports, _activeModelAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.ActiveModelSerializer.extend({
    normalizeFindAllResponse: function normalizeFindAllResponse(store, primaryModelClass, payload, _id, _requestType) {
      var mappedProducts = payload.map(function (json) {
        return {
          id: json.id,
          type: 'material',
          attributes: {
            background_color: "#".concat(json.color || '878787'),
            MaterialDensity: json.density,
            MaterialNameShort: json.abbreviation,
            MaterialNameLong: json.name,
            shipperId: json.shipper_id
          }
        };
      });
      return {
        data: mappedProducts
      };
    }
  });

  _exports.default = _default;
});