define("bottomline-execution/serializers/bx/custom-field", ["exports", "@ember/object", "@ember/service", "@ember/utils"], function (_exports, _object, _service, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _object.default.extend({
    store: (0, _service.inject)(),
    serialize: function serialize(snapshot) {
      var shipperId = snapshot.attr('shipperId');
      var activityCode = snapshot.attr('ActivityCode');
      var customFields = [];

      switch (activityCode) {
        case 'L':
          customFields = [this._batchNumber(snapshot), this._expiryDate(snapshot)];
          break;

        case 'U':
          customFields = [this._barrelCount(snapshot, shipperId), this._dropCount(snapshot, shipperId), this._filledUp(snapshot, shipperId), this._handlingFee(snapshot, shipperId), this._waitingTime(snapshot, shipperId)];
          break;

        default:
          console.warn("Custom fields for stops with ActivityCode '".concat(activityCode, "' not supported"));
      }

      return customFields.flat().compact();
    },
    _barrelCount: function _barrelCount(snapshot, shipperId) {
      var barrelCount = snapshot.attr('BarrelCount');
      if ((0, _utils.isBlank)(barrelCount)) return null;

      var barrelCountDefinitionId = this._customFieldDefinitionId(shipperId, 'barrel_count');

      var site = this._site(snapshot);

      return this._customField(barrelCountDefinitionId, site.id, barrelCount);
    },
    _batchNumber: function _batchNumber(snapshot) {
      var _this = this;

      var loadId = snapshot.attr('loadUUID');
      return this._tripStopStorages(snapshot).map(function (depotStorage) {
        return _this._depotStorageCustomField(depotStorage, loadId, 'batchNumber', 'batch_number');
      });
    },
    _dropCount: function _dropCount(snapshot, shipperId) {
      var _this2 = this;

      var dropCountDefinitionid = this._customFieldDefinitionId(shipperId, 'drop_count');

      return this._tripStopStorages(snapshot).map(function (siteStorage) {
        return _this2._siteStorageCustomField(siteStorage, 'DropCount', dropCountDefinitionid);
      });
    },
    _expiryDate: function _expiryDate(snapshot) {
      var _this3 = this;

      var loadId = snapshot.attr('loadUUID');
      return this._tripStopStorages(snapshot).map(function (depotStorage) {
        return _this3._depotStorageCustomField(depotStorage, loadId, 'expiryDate', 'expiry_date');
      });
    },
    _filledUp: function _filledUp(snapshot, shipperId) {
      var _this4 = this;

      var filledUpDefinitionId = this._customFieldDefinitionId(shipperId, 'filled_up');

      return this._tripStopStorages(snapshot).map(function (siteStorage) {
        return _this4._siteStorageCustomField(siteStorage, 'TankFilledYN', filledUpDefinitionId, {
          defaultValue: false
        });
      });
    },
    _handlingFee: function _handlingFee(snapshot, shipperId) {
      var handlingFee = snapshot.attr('handlingFee');
      if ((0, _utils.isBlank)(handlingFee)) return null;

      var handlingFeeDefinitionId = this._customFieldDefinitionId(shipperId, 'handling_fee');

      var site = this._site(snapshot);

      return this._customField(handlingFeeDefinitionId, site.id, handlingFee);
    },
    _waitingTime: function _waitingTime(snapshot, shipperId) {
      var waitingTime = snapshot.attr('waitingTime');
      if ((0, _utils.isBlank)(waitingTime)) return null;

      var waitingTimeDefinitionId = this._customFieldDefinitionId(shipperId, 'waiting_time');

      var site = this._site(snapshot);

      return this._customField(waitingTimeDefinitionId, site.id, waitingTime);
    },
    _depotStorageCustomField: function _depotStorageCustomField(depotStorage, loadId, attributeName, customFieldName) {
      var value = depotStorage.attr(attributeName);
      if ((0, _utils.isBlank)(value)) return null;
      var material = this.get('store').peekRecord('material', depotStorage.attr('MaterialID'));

      var definitionId = this._customFieldDefinitionId(material.get('shipperId'), customFieldName);

      return this._customField(definitionId, material.id, value, loadId);
    },
    _siteStorageCustomField: function _siteStorageCustomField(siteStorage, attributeName, definitionId) {
      var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {
        defaultValue: null
      };
      var value = siteStorage.attr(attributeName) || options.defaultValue;
      if ((0, _utils.isBlank)(value)) return null;

      var storage = this._storage(siteStorage);

      return this._customField(definitionId, storage.id, value);
    },
    _customField: function _customField(definitionId, targetId, value, contextId) {
      if ((0, _utils.isBlank)(definitionId)) return null;
      return {
        definition_id: definitionId,
        target_id: targetId,
        value: value.toString(),
        context_id: contextId
      };
    },
    _customFieldDefinitionId: function _customFieldDefinitionId(shipperId, name) {
      var customFieldDefinition = this._customFieldDefinitions(shipperId).findBy('name', name) || {};
      return customFieldDefinition.id;
    },
    _customFieldDefinitions: function _customFieldDefinitions(shipperId) {
      return this.get('store').peekAll('custom-field-definition').filterBy('shipperId', shipperId);
    },
    _site: function _site(tripStopSnapshot) {
      return tripStopSnapshot.belongsTo('site');
    },
    _tripStopStorages: function _tripStopStorages(tripStopSnapshot) {
      return tripStopSnapshot.hasMany('trip_stop_storages');
    },
    _storage: function _storage(siteStoragesSnapshot) {
      return siteStoragesSnapshot.belongsTo('storage');
    }
  });

  _exports.default = _default;
});